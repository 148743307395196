import React from 'react';
import { motion } from 'framer-motion';
import Typist from 'react-typist';

import SEO from '../components/seo';

import 'react-typist/dist/Typist.css';

const Home = () => (
	<section className="home">
		<SEO
			title="Agence Web Genève"
			description="Agence de développement web à Meyrin, Genève. Développement d'applications Web & Mobile, Création de site internet, Stratégie Digital."
		/>

		<motion.div
			className="bar-1"
			animate={{
				backgroundPositionY: 0
			}}
			transition={{
				duration: 1,
				type: 'spring'
			}}
		/>
		<motion.div
			className="bar-2"
			animate={{
				backgroundPositionY: 0
			}}
			transition={{
				duration: 1,
				type: 'spring'
			}}
		/>

		<div className="text-content">
			<h2>
				Spécialisé dans la recherche de solutions adaptées à travers &nbsp;
				<div>
					<Typist startDelay={800}>
						le Consulting.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Développement.
						<Typist.Backspace count={17} delay={500} />
						<Typist.Delay ms={500} />
						les Sites Web.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Webmarketing.
						<Typist.Backspace count={16} delay={500} />
						<Typist.Delay ms={500} />
						le Consulting.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Développement.
						<Typist.Backspace count={17} delay={500} />
						<Typist.Delay ms={500} />
						les Sites Web.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Webmarketing.
						<Typist.Backspace count={16} delay={500} />
						<Typist.Delay ms={500} />
						le Consulting.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Développement.
						<Typist.Backspace count={17} delay={500} />
						<Typist.Delay ms={500} />
						les Sites Web.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Webmarketing.
						<Typist.Backspace count={16} delay={500} />
						<Typist.Delay ms={500} />
						le Consulting.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Développement.
						<Typist.Backspace count={17} delay={500} />
						<Typist.Delay ms={500} />
						les Sites Web.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Webmarketing.
						<Typist.Backspace count={16} delay={500} />
						<Typist.Delay ms={500} />
						le Consulting.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Développement.
						<Typist.Backspace count={17} delay={500} />
						<Typist.Delay ms={500} />
						les Sites Web.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Consulting.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Développement.
						<Typist.Backspace count={17} delay={500} />
						<Typist.Delay ms={500} />
						les Sites Web.
						<Typist.Backspace count={14} delay={500} />
						<Typist.Delay ms={500} />
						le Webmarketing.
					</Typist>
				</div>
			</h2>

			<div>
				<p className="mobile-hidden"><em>"Grâce à nos 10 ans d'expérience dans le développement web, nous avons développé un workflow axé sur les nouvelles technologies pour des acteurs locaux en Suisse."</em></p>
				<p>nammu.ch est une société de conseil en <strong>transformation numérique</strong> et de <strong>développement web</strong>, guidant les entreprises clientes à démêler les problèmes complexes qui émergent toujours au cours de leur <strong>évolution numérique</strong>. Notre expérience dans le monde du web, des technologies émergentes et des nouveaux médias, nous donne la possibilité d’aider nos partenaires à définir correctement leurs <strong>besoins</strong> et <strong>développer</strong> en conséquence des outils leurs permettant d’atteindre plus efficacement leurs <strong>objectifs</strong>.</p>
			</div>
		</div>
	</section>
);

export default Home;
